class Renderer {
    constructor(canvas) {
        this.canvas = canvas;
        this.gl = canvas.getContext('webgl');

        if (!this.gl) {
            throw new Error('WebGL is not supported on this browser.');
        }

        this.init();
    }

    init() {
        const gl = this.gl;

        // Vertex shader program
        const vertexShaderSource = `
            attribute vec2 a_position;
            uniform vec2 u_resolution;
            void main() {
                // Convert the position from pixels to clipspace
                vec2 zeroToOne = a_position / u_resolution;
                vec2 zeroToTwo = zeroToOne * 2.0;
                vec2 clipSpace = zeroToTwo - 1.0;

                // Flip the y-axis because WebGL's clip space is inverted
                gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);
            }
        `;

        // Fragment shader program
        const fragmentShaderSource = `
            precision mediump float;
            uniform vec4 u_color;
            void main() {
                gl_FragColor = u_color;
            }
        `;

        // Create and compile shaders
        const vertexShader = this.createShader(gl.VERTEX_SHADER, vertexShaderSource);
        const fragmentShader = this.createShader(gl.FRAGMENT_SHADER, fragmentShaderSource);

        // Link shaders into a program
        this.program = this.createProgram(vertexShader, fragmentShader);

        // Look up attribute and uniform locations
        this.positionLocation = gl.getAttribLocation(this.program, 'a_position');
        this.resolutionLocation = gl.getUniformLocation(this.program, 'u_resolution');
        this.colorLocation = gl.getUniformLocation(this.program, 'u_color');

        // Create a buffer to store rectangle positions
        this.positionBuffer = gl.createBuffer();
    }

    createShader(type, source) {
        const gl = this.gl;
        const shader = gl.createShader(type);
        gl.shaderSource(shader, source);
        gl.compileShader(shader);
        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
            const error = gl.getShaderInfoLog(shader);
            gl.deleteShader(shader);
            throw new Error(`Failed to compile shader: ${error}`);
        }
        return shader;
    }

    createProgram(vertexShader, fragmentShader) {
        const gl = this.gl;
        const program = gl.createProgram();
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);
        if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
            const error = gl.getProgramInfoLog(program);
            gl.deleteProgram(program);
            throw new Error(`Failed to link program: ${error}`);
        }
        return program;
    }

    render(objects) {
        const gl = this.gl;

        // Set WebGL viewport and clear canvas
        gl.viewport(0, 0, this.canvas.width, this.canvas.height);
        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT);

        // Use the program
        gl.useProgram(this.program);

        // Set the resolution uniform
        gl.uniform2f(this.resolutionLocation, this.canvas.width, this.canvas.height);

        // Bind the position buffer
        gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer);
        gl.enableVertexAttribArray(this.positionLocation);
        gl.vertexAttribPointer(this.positionLocation, 2, gl.FLOAT, false, 0, 0);

        objects.forEach(object => {
            // Compute rectangle corners
            const x = object.position.x - object.dimensions.width / 2;
            const y = object.position.y - object.dimensions.height / 2;
            const width = object.dimensions.width;
            const height = object.dimensions.height;

            const vertices = [
                x, y,
                x + width, y,
                x, y + height,
                x, y + height,
                x + width, y,
                x + width, y + height
            ];

            // Load rectangle data into the buffer
            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW);

            // Set the color uniform (random color for demonstration)
            const color = [ object.id===1?255:0, object.id===2?255:0, object.id===3?255:0, 1.0];
            gl.uniform4fv(this.colorLocation, color);

            // Draw the rectangle
            gl.drawArrays(gl.TRIANGLES, 0, 6);
        });
    }
}

export default Renderer;