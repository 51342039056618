import "./Header.css"
import React, { useState } from "react";
import { ReactComponent as Menu } from '../../Icons/Menu.svg';
import { useNavigate } from "react-router-dom";

const Header=(props)=>{

    const [navOpen, openNav] = useState(false)
    const [settingsOpen,openSettings] = useState(false)

    const navigator = useNavigate()

    return(
        <header>
                <div class="header_static">
                    <Menu onClick={()=>{openNav(!navOpen);openSettings(false)}} class={navOpen?"icon_dropped":"icon"}/>
                    <span onClick={()=>{navigator("/")}} class="title">Jozef Janosko</span>
                    <span onClick={()=>{openSettings(!settingsOpen);openNav(false)}} class={settingsOpen?"item_dropped subheader":"item subheader"}>Page Settings</span>
                    <a href="./Resume_Redacted.pdf" download className="item subheader" >Resumé ⭳</a>
                </div>
                {navOpen&&
                    <nav class="navigator">
                        <span onClick={()=>{navigator("/")}} class='plain nav_item'>Home</span>
                        <span onClick={()=>{navigator("/animated_background")}} class='plain nav_item'>Animated Background</span>
                        <span onClick={()=>{navigator("/collision_rect")}} class='plain nav_item'>Rectancles Collide</span>
                    </nav>
                }
                {settingsOpen&&
                    <div class="navigator">
                        {props.hideContent&&
                            <span onClick={()=>props.hideContent(!props.contentHidden)} class='plain nav_item'>{props.contentHidden?"Show Content":"Hide Content"}</span>
                        }
                    </div>
                }
        </header>
    )
}
export default Header