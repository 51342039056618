import React from "react";
import "./CollisionsRectangle.css"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import SimpleCollision from "../../Games/SimpleCollision/SimpleCollision";

const CollisionRectangles=(props)=>{

    return(
        <div class="collision_rect">
            <Header />
            <main>
                <p className="header">Collisions</p>
                <section class="game">
                    <SimpleCollision/>
                </section>
                <section class="text_section">
                    <p class="subheader">Overview</p>
                    <p className=" indent">This page simulates and displays elastic collisions between objects with mass, velocity, and volume in a 2D space. This is accomplished with three different parts:</p>
                    <ol>
                        <li>ObjectHandler, which defines the structure for objects and stores a list of objects being displayed in this environment.</li>
                        <li>Renderer, which reads a list of objects and renders them onto a canvas element using webGL.</li>
                        <li>Physics, which updates the objects positions, checks for collisions, and changes the momentum for an object when it collides with something. This is repeated every frame.</li>
                    </ol>
                    <p>Out of these three classes, Physics is the most complicated, and is the one I'd like to explain in depth.</p>
                </section>
                <section class="text_section">
                    <p class="subheader">Object Structure</p>
                    <p className=" indent">An object, in this program, is a structure that has a position (x and y), a velocity (x and y), dimensions (width and height), and mass. The position is the center of the object, which we use (along with the dimensions) to draw the four points of the rectangle using webGL. ObjectHandler stores an array of objects, in this case three objects. Every frame, ObjectHandler passes this array to Physics, so we can update the position of the objects and handle collisions. We then pass the array to Renderer after the objects are updated, so they can be drawn to screen.</p>
                </section>
                <section class="text_section">
                    <p class="subheader">The Physics Class</p>
                    <p className=" indent">The physics class handles the position, velocity, and acceleration of all objects. Every frame, Physics.update() is called. The object handler is used to pass the list of objects to Physics, and I also pass the x/y values for the horizontal and vertical boundaries.</p>
                    <p className=" indent">The update function first calls Physics.handleCollision(), which we forward all the parameters to. This function loops through the list of objects, and begins by calling Physics.checkBoundaryCollision(), which we pass the object we're checking, and the boundary values. The checkBoundaryCollision will reverse the x or y velocity if the object is colliding with horizontal or vertical boundaries respectively. We then loop through all objects appearing after the current object in the objects array, and handle collisions between the current objects and the rest of the objects. This is accomplished using checkCollision, which takes two objects and returns true if they are colliding, and collisionResponse, which handles the "bouncing" between the two objects that are colliding.</p>
                    <p className=" indent">Physics.collisionResponse() takes the two objects that are colliding as arguments. Each object is assigned a new velocity using the getFinalVelocity, which simply draws a line between the center points of the two objects, and uses that line to redirect the velocity for the objects. The speed of the object is calculated using the velocity of the object it's colliding with, and mass of both objects to simulate an inelastic collision.</p>
                    <p className=" indent">After collisions are handled, the positions of the objects are updated from Physics.update() using Physics.progress(), which moves the objects an incremental amount using the objects' velocity.</p>
                </section>
                <section class="text_section">
                    <p class="subheader">The Renderer</p>
                    <p className=" indent">Rendering is the most straightforward part of the process. After Physics.update() is called, Renderer.render() is called, which takes the list of objects from the object handler. Each Object is drawn using WebGL onto the canvas as a rectangle, using the object's position and dimensions. This process is repeated each frame, ensuring smooth animation. I chose to use WebGL over 2D-Canvas context because I believe a more complex physics engine would use WebGL for better performance and support for 3D rendering, though I believe I could have used 2D-Canvas context to create the same effect.</p>
                    <p></p>
                </section>
            </main>
            <Footer />
        </div>
    )
}
export default CollisionRectangles