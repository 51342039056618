
import {Route, Routes, useNavigate} from 'react-router-dom'
import Home from './Screens/Home/Home';
import AnimatedBackgroundPage from './Screens/AnimatedBackground/AnimatedBackground';
import { MathJaxContext } from 'better-react-mathjax';

import "./Style/Fonts.css"
import CollisionRectangles from './Screens/CollisionRectangles/CollisionRectangles';

function App() {

  const navigation=useNavigate()
  return (
    <MathJaxContext>
    <div className="container">
      <Routes>
        <Route path="/" element={<Home navigator={navigation}/>} />
        <Route path="/animated_background" element={<AnimatedBackgroundPage navigator={navigation}/>} />
        <Route path="/collision_rect" element={<CollisionRectangles navigator={navigation}/>} />
      </Routes>
    </div>
    </MathJaxContext>
  );
}

export default App;
