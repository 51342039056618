import React, { useRef, useEffect } from 'react'
import "./canvas.css"

/**
 * Canvas
 * @param {Component} props 
 * @returns canvas element
 * 
 * This is a component that takes in a "draw" function, which defines a 2d animation
 * using the context of the canvase element. I'm setting this up so I can pass it animation
 * as a prop, allowing for reuse of the component for different animation.
 * 
 * This component is an adaptation of Lucas Miranda's Canvas component, which you can find
 * here: https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
 * 
 */
const Canvas = props => {
  //pull the draw function from props
  const { draw } = props

  //declare a reference for the canvas element.
  const canvasRef = useRef(null)
  
  useEffect(() => {
    //get canvas context
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    let frameCount = 0
    let animationFrameId

    //set width and height to the dimensions of th parent element of the canvas
    canvas.width=canvas.parentElement.getBoundingClientRect().width;
    canvas.height=canvas.parentElement.getBoundingClientRect().height;
    console.log(canvas.parentElement.getBoundingClientRect().height," - ", canvas.height)
  
    //increments frame count and schedules the next frame to be drawn
    const render = () => {
      frameCount++
      draw(context, frameCount)
      //we store the id of the next frame in case we have to cancel the animation
      animationFrameId = window.requestAnimationFrame(render)
    }
    //initial render
    render()
    
    //resizes the canvas element to match the parent's dimensions
    const handleResize = () => {
      const parent = canvas.parentElement;
      if (parent) {
        const parentWidth = parent.getBoundingClientRect().width;
        /**
         * the canvas forces its parent element to be at least 5 pixels taller than canvas,
         * this leads to infinite growth of the canvas component on resize,
         * 
         */
        
        const parentHeight = parent.getBoundingClientRect().height; 
        
        
        canvas.width = parentWidth;
        
        if(canvas.parentElement.className==="canvas_wrapper")
          canvas.height = parentHeight-5;
        else
          canvas.height=parentHeight
        if(canvas.parentElement.className==="canvas_wrapper")
        console.log(canvas.parentElement.className," [",parent.getBoundingClientRect().width," , ",parent.getBoundingClientRect().height,"] - ","Canvas resized to:", canvas.width, canvas.height);
        context.clearRect(0, 0, canvas.width, canvas.height);
      }
    //window.addEventListener('resize', handleResize);
  };
    //add a listener to handle resizing whenever the window is resized
    window.addEventListener('resize', handleResize);

    //when the component unmounts, cancel the animation and stop listening for resizes
    return () => {
        window.cancelAnimationFrame(animationFrameId)
        window.removeEventListener('resize', handleResize)
    }
  }, [draw])
  
  //this component returns a single canvas element
  return <canvas class={props.bordered&&"bordered"} ref={canvasRef}/>
}
export default Canvas