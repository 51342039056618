class Physics{

    handleCollision(objects,leftbound,rightbound,topbound,bottombound){
        //loop through objects and do collision detectiona and response
        for(let i = 0; i<objects.length;i++){
            //handle colisions with boundary
            this.checkBoundaryCollision(objects[i],leftbound,rightbound,topbound,bottombound);
            //handle collisions with other objects
            for (let j = i + 1; j < objects.length; j++) {
                if (this.checkCollision(objects[i], objects[j])) {
                    this.collisionResponse(objects[i],objects[j]);
                }
            }
        }

    }

    checkBoundaryCollision(object,leftbound,rightbound,topbound,bottombound){
        //determines the edge positions for the rectangle
        const leftEdge = object.position.x - object.dimensions.width/2
        const rightEdge = object.position.x + object.dimensions.width/2
        const topEdge = object.position.y + object.dimensions.height/2
        const bottomEdge = object.position.y - object.dimensions.height/2

        if( leftEdge<=leftbound ){
            object.velocity.x = Math.abs(object.velocity.x)
            //console.log("out of bounds")
        }else if( rightEdge>=rightbound ){
            object.velocity.x = 0 - Math.abs(object.velocity.x)
        }
        if( bottomEdge<=bottombound ){
            object.velocity.y = Math.abs(object.velocity.y)
            //console.log("out of bounds")
        }else if( topEdge>=topbound ){
            object.velocity.y = 0- Math.abs(object.velocity.y)
        }
    }

    //returns true if objects are colliding, otherwise returns false
    checkCollision( object1, object2 ){

        // Check if the two objects are colliding based on AABB
        const object1Left = object1.position.x - object1.dimensions.width / 2;
        const object1Right = object1.position.x + object1.dimensions.width / 2;
        const object1Top = object1.position.y - object1.dimensions.height / 2;
        const object1Bottom = object1.position.y + object1.dimensions.height / 2;

        const object2Left = object2.position.x - object2.dimensions.width / 2;
        const object2Right = object2.position.x + object2.dimensions.width / 2;
        const object2Top = object2.position.y - object2.dimensions.height / 2;
        const object2Bottom = object2.position.y + object2.dimensions.height / 2;

        // Check for overlap on both the x and y axes
        const isColliding = !(object1Right < object2Left || 
                                object1Left > object2Right ||
                                object1Bottom < object2Top ||
                                object1Top > object2Bottom);

        return isColliding;
    }

    collisionResponse(object1,object2){

        let neoV1 = this.getFinalVelocity(object1,object2)
        let neoV2 = this.getFinalVelocity(object2,object1)
        //alter object1
        object1.velocity=neoV1
        object2.velocity=neoV2
        
        //console.log(normal2)
        
    }

    /**
     * 
     * @param {object} object1 - relativeistic observer - object we are "acting upon" 
     * @param {object} object2  - the collider, the object which "acts upon" (I didn't forget the 3rd law of motion)
     * @returns {tuple} final_velocity
     */
    getFinalVelocity(object1,object2){
        const m1 = object1.mass;
        const m2 = object2.mass;

        // Compute relative velocity
        const vRelX = object1.velocity.x - object2.velocity.x;
        const vRelY = object1.velocity.y - object2.velocity.y;

        // Compute collision normal vector
        const dx = object1.position.x - object2.position.x;
        const dy = object1.position.y - object2.position.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const nx = dx / distance;
        const ny = dy / distance;

        // Compute relative velocity along the normal
        const vRelN = vRelX * nx + vRelY * ny;

        // If objects are moving apart, return the original velocity of object1
        if (vRelN >= 0) {
            return { x: object1.velocity.x, y: object1.velocity.y };
        }

        // Calculate impulse scalar
        const J = (-2 * vRelN) / (1 / m1 + 1 / m2);

        // Calculate final velocity of object1
        const finalVelocityX = object1.velocity.x + (J / m1) * nx;
        const finalVelocityY = object1.velocity.y + (J / m1) * ny;

        return { x: finalVelocityX, y: finalVelocityY };
    }

    progress(objects, leftbound, rightbound, bottombound, topbound){
        objects.forEach(object => {
            object.velocity = { x: object.velocity.x+object.force.x/object.mass, y: object.velocity.y+object.force.y/object.mass}
            object.position = { x: object.position.x+object.velocity.x, y: object.position.y+object.velocity.y }
            //console.log(object.velocity,"  -progress")
        });
    }

    update(objects, leftbound, rightbound, bottombound, topbound){
        this.handleCollision(objects, leftbound, rightbound, topbound, bottombound);
        this.progress(objects);
    }
}
export default Physics