/* class Object{
    
    constructor(position = { x: 0, y: 0 }, velocity = { x: 0, y: 0 }, force = { x: 0, y: 0 }, mass = 1, id = "", dimensions = { width:0, heigh:0 }) {
        this.position = position;       //set initial position
        this.velocity = velocity;       // set initial velocity
        this.force = force;             //set force of the object
        this.mass = mass;               //set the mass of the object
        this.id=id                      //set the id -- IMUTABLE
        this.dimensions = dimensions    //set dimensions
    }

    update(position = { x: 0, y: 0 }, velocity = { x: 0, y: 0 }, force = { x: 0, y: 0 }, mass = 1,dimensions = { width:0, heigh:0 }){
        this.position = position;       //update position
        this.velocity = velocity;       //update velocity
        this.force = force;             //update force of the object
        this.mass = mass;               //update mass of the object
        this.dimensions = dimensions    //update dimensions
    }
} */

class ObjectHandler{
    constructor(){
        this.objects=[]
    }
    addObject(object){
        this.objects.push(object)
    }
    removeObject(object){
        this.objects = this.objects.filter(obj=>obj.id!==object.id)
    }
}
export default ObjectHandler