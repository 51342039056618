import React, { useRef, useEffect } from 'react';
import ObjectHandler from './ObjectHandler';
import Physics from './Physics';
import Renderer from './Renderer';

const SimpleCollision = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const objectHandler = new ObjectHandler();
    const physics = new Physics();
    const renderer = new Renderer(canvas);

    // Set canvas dimensions
    const resizeCanvas = () => {
      canvas.width = canvas.parentElement.offsetWidth;
      canvas.height = canvas.parentElement.offsetHeight;
    };
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    // Add initial objects to ObjectHandler
    objectHandler.addObject({
      id: 1,
      position: { x: 100, y: 100 },
      velocity: { x: 2, y: 3 },
      force: { x: 0, y: 0 },
      mass: 2,
      dimensions: { width: 60, height: 60 },
    });

    objectHandler.addObject({
      id: 2,
      position: { x: 200, y: 200 },
      velocity: { x: -1, y: -1 },
      force: { x: 0, y: 0 },
      mass: 1,
      dimensions: { width: 40, height: 40 },
    });

    objectHandler.addObject({
        id: 3,
        position: { x: 400, y: 200 },
        velocity: { x: -1, y: 0 },
        force: { x: 0, y: 0 },
        mass: 5,
        dimensions: { width: 100, height: 100 },
      });

    // Animation loop
    let animationFrameId;
    const animate = () => {
      physics.update(objectHandler.objects, 0, canvas.width, 0, canvas.height); // Update physics
      renderer.render(objectHandler.objects); // Render objects
      animationFrameId = requestAnimationFrame(animate); // Loop
    };

    animate(); // Start animation

    return () => {
      cancelAnimationFrame(animationFrameId); // Clean up animation
      window.removeEventListener('resize', resizeCanvas); // Remove resize listener
    };
  }, []);

  return <canvas ref={canvasRef} id="collision_game_container" style={{ width: '100%', height: '100%',border:"1px solid white" }} />;
};

export default SimpleCollision;